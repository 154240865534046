.search-btn {
    background: red;
    color: #fff;
    line-height: 30px;
    border: none;
    width: 20%;
    border-radius: 5px;
}
.alizi-input-text {
    height: 15px;
    line-height: 15px;
    width: 90%;
}
div.RecommendGoods {
    overflow: hidden;
    border-top: 1px solid #ddd;
    padding-top: 6px;
    background: #fff;
    margin: 2% 2.5% 0;
}
.RecommendGoods div.titleName {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-indent: 2%;
    border-bottom: 1px solid #ccc;
    background: #fff;
    margin-bottom: 10px;
}

.warn-box {
    font-size: 12px;
    background: #eee;
    padding: 10px 2.5%;
    line-height: 1.2;
    margin: 0px 5px;
    color: #000;
}
.warn-box em {
    color: red;
}
.tcenter {
    text-align: center;
    margin: 20px 0px;
}
.backbtn {
    background: red;
    color: #fff;
    padding: 5px 15px;
}
