.cod-toast.fadeout {
    opacity: 0.2;
    transition: opacity 350ms ease-in;
}

.cod-toast:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
}

.cod-toast__text {
    word-wrap: break-word;
    word-break: break-word;
}

.cod-toast {
    position: fixed;
    z-index: 1050;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
}

.cod-toast__container {
    display: inline-block;
    vertical-align: middle;
    padding: 0.91667em 1.16667em;
    max-width: 80%;
    min-width: 8.33333em;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    line-height: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.cod-toast__content {
    display: inline-block;
    position: relative;
}

.cod-toast__text {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    font-size: 1.08333em;
    line-height: 1.5;
}

.cod-toast.click_through {
    pointer-events: none;
}

.cod-toast.click_through .cod-toast__container {
    pointer-events: auto;
}
