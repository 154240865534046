@import './assets/style/alizi-order.css';
@import './assets/style/css.css';
@import './assets/style/style.css';

.clearfix {
    *zoom: 1;
    margin: 0 auto;
    max-width: 680px;
}
.alizi-params {
    float: none;
    padding: 0.4rem;
    font-size: 1rem;
    display: block;
    margin: 1%;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.alizi-params.active {
    border: 1px solid #f60;
    box-shadow: 0px 0px 0px 1px #f60;
}
.alizi-group {
    position: relative;
    display: inline-block;
}

.goods_img {
    display: inline-block;
}
.pic {
    width: 28%;
    float: left;
}
.pic img {
    width: 100%;
}
.pic_str {
    margin-left: 5%;
    width: 65%;
    float: left;
    color: #000;
}
.img_str {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.title {
    text-align: center;
    color: #000;
    line-height: 3rem;
    background-size: 1rem;
    font-size: 130%;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    width: 100%;
    top: 0px;
    z-index: 99;
    max-width: 680px;
}
.title-back {
    background: url(./assets/images/back.png) no-repeat 2% 50%;
    width: 1.5rem;
    height: 1.5rem;
    float: left;
    vertical-align: middle;
    line-height: 3rem;
    clear: both;
    padding: 0.75rem 0;
}
