* {
    margin: 0;
    padding: 0;
}
html {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #fff;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    font: 14px/1.5 'Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei', serif;
}
h1 {
    font-size: 1.5rem;
    padding: 14px 0;
    color: #000;
    text-align: center;
}
h2 {
    font-size: 16px;
}
a {
    color: #eb5f3e;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
.label {
    background: #eb5f3e;
    padding: 5px 8px;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
    vertical-align: middle;
}
.wrapper {
    background-color: #f1f1f1;
    background-color: rgba(255, 255, 255, 0.2);
    max-width: 680px;
    margin: 0 auto;
}
.header {
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
}
.alizi-detail-content {
    padding: 0 10px;
}
.alizi-detail-content video {
    width: 100%;
    height: 75%;
}
.alizi-page {
    padding: 0 2.5%;
}
.alizi-page img {
    max-width: 100%;
    width: 100%;
}
.alizi-page .box {
    margin: 15px 10px;
    padding: 5px 0;
    background: #fff;
}
.alizi-page .box a:hover {
    text-decoration: none;
}
.alizi-page .box a:hover span {
    text-decoration: underline;
}
.alizi-page .box a:hover .label {
    text-decoration: none;
}
.alizi-page .box h2.title {
    margin: 0;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    font-weight: normal;
    padding-left: 27px;
}
.alizi-page .box-image .box-content {
    text-align: center;
}
.alizi-page .box .box-content table.links {
    margin-top: -1px;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}
.alizi-page .box .box-content table.links td {
    padding: 3px 3px 3px 10px;
    border: 1px solid #ddd;
    border-left: 0;
}
.alizi-page .box .box-content table.links td:nth-child(2) {
    border-left: 1px solid #ddd;
    border-right: 0;
}
.alizi-page .box .box-content table.links td a {
    display: block;
}
.alizi-page .alizi-order {
    border-bottom: none;
}
.alizi-remark {
    color: #ccc;
    padding-top: 10px;
}
.alizi-footer {
    color: #888;
    text-align: center;
    border-top: none;
}
.alizi-footer p {
    padding: 0 0 20px 0;
    font-size: 12px;
}

.alizi-plug {
    height: 54px;
    color: #fff;
    overflow: hidden;
}
.alizi-plug .price {
    float: left;
    height: 54px;
    width: 60%;
    background: #f61d4b;
    vertical-align: bottom;
}
.alizi-plug .price .symbol {
    float: left;
    vertical-align: bottom;
    padding-top: 28px;
}
.alizi-plug .current-price {
    float: left;
    font-size: 45px;
    line-height: 54px;
    vertical-align: bottom;
    letter-spacing: -3px;
}
.alizi-plug .group {
    float: left;
    margin-left: 5px;
}
.alizi-plug .original-price {
    padding-top: 5px;
    line-height: 20px;
    vertical-align: top;
    display: block;
}
.alizi-plug .salenum {
    line-height: 20px;
    vertical-align: bottom;
    height: 25px;
    line-height: 25px;
    padding: 2px;
    background: #ca032e;
    border-radius: 1px;
}
.alizi-plug .timer {
    float: left;
    height: 54px;
    width: 40%;
    background: #eee;
    color: #555;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
}
.alizi-plug .timer .tt {
    padding-top: 5px;
    color: #f00;
}

.alizi-content-title {
    padding: 15px;
    border-bottom: 1px solid #eee;
}
.baoyou {
    height: 45px;
    line-height: 25px;
    border-bottom: 1px #eee solid;
    overflow: hidden;
    padding: 10px 0 10px 10px;
}
.baoyou span {
    padding-left: 22px;
    float: left;
    margin-right: 5px;
    display: inline;
    font-size: 13px;
    background-size: 20px 20px;
}

.gou {
    margin: 15px;
    height: 58px;
    background: #c60a1e;
    border-radius: 3px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 58px;
}
.gou a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
}

.index_li_goods {
    list-style: none;
}

.index_li_goods li {
    display: inline;
    margin: 0 4px 4px 0;
    line-height: 20px;
    vertical-align: middle;
    padding: 3px 5px;
    width: auto !important;
    text-align: center;
    color: black;
    background-color: white;
}

.index_li_color {
    list-style: none;
}

.index_li_color li {
    display: inline;
    margin: 0 4px 4px 0;
    line-height: 20px;
    vertical-align: middle;
    padding: 3px 5px;
    width: auto !important;
    text-align: center;
    color: black;
    background-color: white;
}
.navdown {
    border: 2px red solid;
}
.navup {
    border: 1px solid #b8b7bd;
}

.service {
    font-size: 0;
    line-height: 40px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ccc;
    padding: 0 3%;
    background: #fff;
    height: 47px;
}
.service .bb {
    width: 33.33%;
    float: left;
}
.service .cdeli {
    text-align: center;
}
.service .shiwu {
    text-align: right;
}

.service .fship span {
    background-position: 0 -80px;
}
.service .shiwu span {
    background-position: 0 -36px;
}
.service .cdeli span {
    background-position: 0 9px;
}

.buy {
    background: #fff;
    height: auto;
    padding: 0px 0px 5px 0px;
}
.title h1 {
    color: #333;
    font-size: 1.1rem;
    line-height: 36px;
    padding: 10px 2% 0 2%;
    font-weight: normal;
    text-align: left;
}
.sales {
    width: 95%;
    margin: 0 auto;
    display: block;
    color: #333;
    position: relative;
    padding: 0 0 10px 0;
    border-radius: 0 28px 28px 0;
}
.sales .price {
    font-size: 14px;
    margin: 0 1%;
    display: inline-block;
}
.sales .price ins {
    font-size: 1.8rem;
    margin-left: 4px;
    font-weight: 600;
}
.sales .sales_info {
    font-size: 12px;
    display: inline-block;
}
.sales .sales_info .sales_num {
    background: #49bde4;
    padding: 2px 8px;
    width: 130px;
    margin:20px auto 0px auto;
    line-height: 2.5em;
    border-radius: 3px;
    color: #fff;
}
/* .sales .sales_info .sales_num {
    background: #49bde4;
    padding: 2px 8px;
    position: absolute;
    right: 50%;
    top: 65%;
    line-height: 2.5em;
    border-radius: 3px;
    color: #fff;
} */
.boom {
    padding: 2% 2.5% 4px 2.5%;
    font-size: 13px;
    color: #f00;
}
.buy_now {
    width: 96%;
    background: -webkit-linear-gradient(top, #343399, #3d71bd);
    margin: 14px 2%;
    text-align: center;
}
.buy_now a {
    color: #fff;
    font-size: 1rem;
    padding: 8px 0;
}
.wttl {
    text-align: left;
}
.wttl h2 {
    background-size: 100% 100%;
    margin: 8px 0 8px -4px;
    display: inline-block;
    padding: 5px 14px 5px 0px;
    text-indent: 0;
    color: #666;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.note_text,
.note_title {
    width: 96%;
    margin: 0 auto;
    color: #333;
    line-height: 25px;
    text-align: left;
    font-size: 0.9rem;
    text-indent: 14px;
}
.note_title {
    color: #e61a1a;
}
.s_order {
  background-size: 100% auto;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 1px #dfdfdf;
}
.fixedbtn {
  position: fixed;
  right: 23px;
  bottom: 60px;
  z-index: 8;
}

.fixedbtn div {
  width: 50px;
  height: 50px;
  margin-bottom: 6px;
}

.fixedbtn div a {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 640px) {
    footer {
        left: 50%;
        margin-left: -304px;
    }
    .sales {
        text-indent: 6px;
        padding-top: 0;
    }
    .buy_now {
        width: 48%;
        margin: 14px auto 8px auto;
    }
    .submit button {
        width: 48%;
        margin: 18px auto 0 auto;
    }
    .top {
        left: 50%;
        margin-left: 260px;
    }
    .title h1 {
        line-height: 46px;
        font-size: 1.4rem;
        padding-top: 10px;
    }
    .sales .price ins {
        font-size: 2rem;
    }
}
