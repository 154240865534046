.weui-weixin-title {
    color: #000;
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 14px;
    border-bottom: 1px solid #e7e7eb;
}
.weui-weixin-info {
    line-height: 20px;
    margin-bottom: 0;
    font-size: 0px;
}
.weui-weixin-em {
    color: #8c8c8c;
    font-style: normal;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 12px;
    vertical-align: middle;
}
.weui-weixin-em a {
    color: #607fa6;
    text-decoration: none;
}
.weui-weixin-tools {
    overflow: hidden;
    padding-top: 15px;
    line-height: 32px;
}
.weui-weixin-read {
    font-size: 14px;
    vertical-align: 0;
    position: relative;
    margin: 0 10px;
    display: inline-block;
    color: #8c8c8c;
}
.weui-weixin-zan {
    background: transparent url('../images/png.png') no-repeat scroll 0 0 / 100% auto;
    display: inline-block;
    height: 13px;
    vertical-align: middle;
    width: 13px;
}
.weui-weixin-title a {
    color: #607fa6;
    text-decoration: none;
}
.right {
    float: right;
    color: #607fa6;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.big {
    text-align: center;
}
.cle {
    clear: both;
    width: 720px;
}
.left {
    float: left;
}
.form-group {
    margin-top: 10px;
}
.form-group .field-title {
    color: #666;
    display: block;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    padding: 20px 0px 15px 0px;
    font-size: 1.2rem;
    margin-bottom: 10px;
}
.goods_img {
    max-width: 640px;
    padding: 5px;
}
.col-xs-12 {
    padding: 0px 2px;
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}
.col-xs-12 a {
    color: blue;
}
.confirm {
    width: 100%;
    left: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    height: 40px;
    box-shadow: 0 2px 8px #999;
    position: fixed;
    bottom: -1px;
    z-index: 101;
}
.confirm .prices {
    float: left;
    height: 100%;
    width: 48%;
    text-align: left;
    line-height: 39px;
    margin-left: 2%;
}
.confirm .submit {
    width: 46%;
    height: 100%;
    float: right;
    line-height: 39px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 0;
    padding: 0;
    font-size: 1.1rem;
    text-align: center;
    color: #fff;
    background: -webkit-linear-gradient(left, #ee3300, #ee3300);
}

.con_ul {
    padding: 0;
    margin: 0;
    display: block;
    overflow: auto;
}
.con_ul li {
    list-style: none;
    padding: 10px 0px;
    margin: 0;
    border-bottom: 1px dashed #ccc;
}
.con_ul li .con_ul_title:before {
    content: '';
    width: 2px;
    height: 100%;
    border: 2px solid #ff9133;
    margin-right: 8px;
}
.con_ul li .con_ul_title {
    background-image: url(../images/method-draw-image.svg);
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: 100% 50%;
    line-height: 1.2rem;
    font-size: 1.2rem;
}
.con_ul li .con_ul_title.act {
    background-image: url(../images/arrow-down.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    background-position: 100% 50%;
}
.con {
    display: none;
    padding: 5px;
    margin: 10px 0;
}
.shopcar-foot {
    padding: 2%;
    font-size: 0.8rem;
    background: #e7e6e6;
    margin: 0px 1rem;
}
/*性别*/
.sex {
    display: inline-block;
    width: 100%;
}
.select {
    display: inline-block;
    width: 100%;
}

.sex span.warning {
    font-size: 1.4rem;
    color: #ccc;
}
.sex .male,
.sex .female {
    position: relative;
    width: 30%;
    float: left;
    margin: 1%;
    height: 2rem;
    z-index: 1;
    line-height: 3.9rem;
    text-align: center;
}
.sex .male label,
.sex .female label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    z-index: 3;
    opacity: 0;
    margin: auto;
    display: inline-block;
    line-height: 3rem;
    cursor: pointer;
}
.sex input {
    display: inline-block;
    vertical-align: middle; /*让默认的单�?�样式的圆圈和�?�男”�?�女”的文本没有高差，看起来在同�?水平�?*/
    height: 2rem;
    line-height: 2.8rem;
    margin: 0; /*清除浏览器默认的外边�?*/
    width: 100%;
}
.sex .male span.btn,
.sex .female span.btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    z-index: 2;
    display: inline-block;
    white-space: nowrap;
    line-height: 2rem;
    text-align: center;
    border: 1px solid #ccc;
    color: #000;
    border-radius: 1rem;
}
.sex .male span {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}
.sex .female span {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}
.sex .male span.active,
.sex .female span.active {
    background-color: #fe5454;
    color: #fff;
}
